import React from "react";
import * as yup from "yup";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Box } from "@mui/material";

import {
  AlphaNumericKeyField,
  RenderLabelValue,
  SearchTungstenTags,
  Select,
  TabsSection,
  TextField,
  TextFieldWithTranslations,
  ToggleButtonGroup
} from "@packages/theme-mui-v5";

import { SchemaType, locales, useTungstenDataSources } from "@packages/service-api";
import SchemaFormField from "./SchemaFormField";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  isEditForm: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  children?: React.ReactNode;
  schemaType: SchemaType;
  isClone?: boolean;
  type?: string;
};

const translationSchema = (fieldName: string) =>
  yup
    .object()
    .shape({
      locale: yup.string().oneOf(locales).required("Locale is required"),
      value:
        fieldName !== "Placeholder"
          ? yup.string().required(`${fieldName} is required`)
          : yup.string().test({
              name: "value-validation",
              test: (_, context) => {
                const placeholderFields = context.from[1].value.placeholder;

                const isPlaceholderEmpty = placeholderFields[0].value === "";
                const isTranscriptionAdded = placeholderFields.length > 1;
                const isTranscriptionAddedEmpty = placeholderFields.some(
                  ({ value }) => value === ""
                );

                if (isTranscriptionAdded && (isPlaceholderEmpty || isTranscriptionAddedEmpty)) {
                  return context.createError({
                    path: context.path,
                    message: "Placeholder is required"
                  });
                }

                return true;
              }
            })
    })
    .noUnknown();

export const baseFieldSchema = yup.object().shape({
  fieldKey: yup.string().required("Field Key is required"),
  label: yup.array().of(translationSchema("Label")).required(),
  placeholder: yup.array().of(translationSchema("Placeholder")),
  type: yup
    .string()
    .oneOf([
      "boolean",
      "markdown",
      "markdown[]",
      "number",
      "reference",
      "select",
      "string",
      "string[]"
    ])
    .required(),
  isRequired: yup.boolean().required().nullable(),
  default: yup.mixed().nullable()
  // decimalPlaces: yup.number().required("Decimal places is required")
});

export const manualEntryFieldSchema = baseFieldSchema.concat(
  yup.object().shape({
    manualEntry: yup.object().shape({
      units: yup.string().required("Units is required"),
      system: yup.string().required("System is required"),
      tagName: yup.string().required("Tag name is required"),
      reductionType: yup
        .string()
        .oneOf(["Raw", "Snapshot", "Average", "Delta", "Min", "Max", "StdDev"])
        .required("Reduction Type is required"),
      reductionOffset: yup
        .string()
        .oneOf(["Before", "After"])
        .required("Reduction offset is required"),
      displayLastValue: yup.boolean().required("Display last value  is required"),
      writable: yup.boolean().required("Writable is required")
    })
  })
);

export const determineBaseFieldSchema = (schemaType: SchemaType) => {
  if (schemaType === "manual-entry") {
    return manualEntryFieldSchema;
  }

  return baseFieldSchema;
};

const BaseFieldFields: React.FC<Props> = (props) => {
  const { type, children, control, isEditForm, schemaType, setValue, watch, isClone } = props;

  const { dataSources } = useTungstenDataSources();
  const system = watch("manualEntry.system", "");
  const writable = watch("manualEntry.writable");
  const reductionOffset = watch("manualEntry.reductionOffset");
  const tagType = watch("manualEntry.tagType");

  React.useEffect(() => {
    if (reductionOffset) {
      setValue("manualEntry.reductionOffset", reductionOffset);
    }
  }, [reductionOffset]);

  const booleanOptions = React.useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ];
  }, []);

  const beforeAfterOptions = React.useMemo(() => {
    return [
      { label: "Before", value: "Before" },
      { label: "After", value: "After" }
    ];
  }, []);

  const showDefaultField = ["number", "string"].includes(type);

  const tabConfig = React.useMemo(() => {
    const tabConfig = {
      Field: 0
    };

    if (schemaType === "manual-entry") {
      tabConfig["Manual Entry"] = 1;
    }

    return tabConfig;
  }, [schemaType]);

  const decimalPlaces = React.useMemo(() => {
    return [0, 1, 2, 3, 4];
  }, []);

  const [activeTab, setActiveTab] = React.useState<number>(tabConfig["Field"]);

  return (
    <>
      <TabsSection
        tabState={activeTab}
        tabs={Object.keys(tabConfig)}
        handleTabChange={(e, tab) => setActiveTab(tab)}
      />
      <Box sx={{ mt: 2 }}>
        {activeTab === 0 ? (
          <>
            {isEditForm ? (
              <RenderLabelValue label="Key" value={watch("fieldKey", "")} />
            ) : (
              <AlphaNumericKeyField
                name="fieldKey"
                label="Key"
                control={control}
                watch={watch}
                setValue={setValue}
                sourceFieldName="label[0].value"
                required
                viewValueByDefault={true}
              />
            )}

            <RenderLabelValue label="Type" value={type} />

            <TextFieldWithTranslations
              name="label"
              label="Label"
              control={control}
              setValue={setValue}
              watch={watch}
            />

            <TextFieldWithTranslations
              name="placeholder"
              label="Placeholder"
              control={control}
              setValue={setValue}
              watch={watch}
            />

            {showDefaultField && (
              <TextField
                name="defaultValue"
                label="Default Value"
                control={control}
                type={type === "number" ? "number" : "string"}
              />
            )}

            {type === "number" && (
              <Select
                options={decimalPlaces}
                name="decimalPlaces"
                label="Decimal Places"
                control={control}
              />
            )}

            <Box pb={2}>
              <ToggleButtonGroup
                options={booleanOptions}
                name="isRequired"
                label="Required"
                control={control}
              />
            </Box>
            {children}
          </>
        ) : (
          <>
            <Select
              options={dataSources}
              name="manualEntry.system"
              label="System"
              control={control}
              empty
              required
            />

            <Box>
              <ToggleButtonGroup
                name="manualEntry.tagType"
                control={control}
                options={[
                  { label: "Existing", value: "existing" },
                  { label: "New", value: "new" }
                ]}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              {tagType === "existing" && (
                <SearchTungstenTags
                  name="manualEntry.tagName"
                  label="Tag"
                  systemSearchTerm={system}
                  control={control}
                />
              )}
              {tagType === "new" && (
                <TextField name="manualEntry.tagName" label="Tag Name" control={control} required />
              )}
            </Box>

            <TextField name="manualEntry.units" label="Units" control={control} required />

            <Select
              name="manualEntry.reductionType"
              options={["Raw", "Snapshot", "Average", "Delta", "Min", "Max", "StdDev"]}
              label="Reduction Type"
              control={control}
              empty
              required
            />

            {/* Looks like ToggleButtonGroup doesn't work with string value on initial render so on each update of this input field we will destroy it re-create component */}
            <Box pb={2} key={reductionOffset}>
              <ToggleButtonGroup
                label="Input Reduction Offset"
                options={beforeAfterOptions}
                name="manualEntry.reductionOffset"
                control={control}
              />
            </Box>
            <Box pb={2}>
              <ToggleButtonGroup
                options={booleanOptions}
                name="manualEntry.displayLastValue"
                label="Display Last Value?"
                control={control}
              />
            </Box>
            <Box pb={2}>
              <ToggleButtonGroup
                options={booleanOptions}
                name="manualEntry.writable"
                label="Writable?"
                control={control}
              />
            </Box>
            {writable && (
              <SchemaFormField
                isEditForm={isEditForm}
                setValue={setValue}
                watch={watch}
                isClone={isClone}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default BaseFieldFields;
