import { Layout, ListOfValuesSchema, ManualEntry } from "@packages/service-api";

import { ColDef } from "@packages/data-grid";
import { formatAccountingNumber } from "@packages/utils";
import { DataType, View } from "./manual-entry/ManualEntryRenderer";

export type RowDataItem = {
  isGroup: boolean;
  headerName?: string;
  isDailyView?: boolean;
  timeStamp: string;
  key?: string;
  value?: string | number;
  dataType?: DataType;
};

export const generateTransposedGridConfig = (
  schema: ListOfValuesSchema,
  manualEntires: ManualEntry[],
  view: View,
  write: boolean
) => {
  const columnDefs: ColDef[] = [];

  if (!view || !manualEntires?.length) {
    return {
      columnDefs: null,
      rowData: null
    };
  }

  columnDefs.push({
    headerName: "",
    field: "headerName",
    colId: "headerName",
    cellClass: "primary-text suppress-range-selection allow-cell-focus-with-primary",
    type: "headerHighlight",
    pinned: "left",
    lockPinned: true,
    sortable: false,
    editable: false
  });

  const numberOfColumns: number = manualEntires?.length;

  [...Array(numberOfColumns).keys()].forEach((i) => {
    columnDefs.push({
      headerName: view === "1Month-Daily" ? `${i + 1}` : `${i < 10 ? "0" : ""}${i}:00`,
      sortable: false,
      type: "rightAlignedHeaderHighlight",
      editable: (params) => {
        return write && !params.data.isGroup && params.data[i]?.isEditableCell;
      },
      colId: `col-${i}`,
      cellEditorParams: (params) => {
        return {
          ...(params.data.dataType === "number" ? { validation: "numberAndDotWithNegative" } : {}),
          type: "text",
          className: params.data.dataType === "string" && "ag-left-aligned"
        };
      },
      cellClass: (params) => {
        const fixedClass = params.data.dataType === "number" && "ag-right-aligned-cell";
        const suppressRangeSelection =
          (params.data?.isGroup || !params?.data[i]?.isEditableCell) && "suppress-range-selection";
        const agEditedCell = params.data[params.colDef.field]?.isEdited && "ag-edited-cell";
        const expected = params.data[i]?.expected && "ag-cell-editable-bg";
        const notAllowed = params.data[i]?.notAllowed && "ag-cell-grey-600";
        return `${fixedClass} ${suppressRangeSelection} ${agEditedCell} ${expected} ${notAllowed}`;
      },
      valueGetter: (params) => {
        return params.data[params.colDef.field]?.value;
      },
      valueSetter: (params) => {
        if (!params.newValue) return false;
        const oldData = params.data[i];
        if (
          oldData.value !== params.newValue &&
          !(oldData.value === undefined && params.newValue === null)
        ) {
          params.data[i] = {
            ...oldData,
            value: params.newValue,
            isEdited: true
          };
          return true;
        }
        return false;
      },
      field: `${i}`,
      valueFormatter: (params) => {
        if (params.data.dataType === "number") {
          return formatAccountingNumber(
            params?.value,
            params.data[params.colDef.field]?.decimalPlaces ?? 1
          );
        }
        return params.value;
      }
    });
  });

  const rowData: RowDataItem[] = [];
  const gridLayout = schema.layout.grid || [];

  const getLayout = (layout: Layout) => {
    return layout.map((item) => {
      let dataType = {};
      let headerName = "";
      if (item.type === "group") {
        headerName = item.name;
      } else {
        const field = schema.fields.find((f) => f.fieldKey === item.key);
        dataType = { dataType: field.type };
        if (field) {
          headerName = field.label[0]?.value;
        }
      }

      rowData.push({
        isGroup: item.type === "group",
        headerName,
        key: item.key,
        timeStamp: null,
        ...dataType
      });
      if (item.type === "group") {
        getLayout(item.layout);
      }
    });
  };

  getLayout(gridLayout);

  if (manualEntires?.length) {
    rowData.forEach((rowItem) => {
      if (!rowItem.isGroup) {
        const isDailyView =
          schema.settings.find((data) => data.key === "timeRange")?.value === "1Day-Hourly";
        const field = schema.fields.find((f) => f.fieldKey === rowItem.key);
        const { manualEntry, decimalPlaces } = field || {};
        const writable = manualEntry.writable;

        manualEntires.forEach((data, j) => {
          const rowData = data?.fieldValues?.find((data) => data.key === rowItem.key);
          const notExpected = isDailyView && manualEntry?.notExpected?.includes(j);
          const notAllowed = isDailyView && manualEntry?.notAllowed?.includes(j);
          const expected = isDailyView && !notExpected && !notAllowed;

          const isEditableCell =
            writable && write && (!isDailyView || (isDailyView && !notAllowed));

          rowItem[`${j}`] = rowData
            ? {
                ...rowData,
                value:
                  rowItem.dataType === "number"
                    ? !isNaN(Number(rowData.value)) && rowData.value !== null
                      ? `${rowData.value}`
                      : null
                    : rowData.value,
                timeStamp: data.timeStamp,
                notExpected,
                expected,
                notAllowed,
                isEditableCell,
                decimalPlaces
              }
            : {
                key: rowItem.key,
                timeStamp: data.timeStamp,
                notExpected,
                expected,
                notAllowed,
                isEditableCell,
                decimalPlaces
              };
        });
      }
    });
  }

  return {
    columnDefs,
    rowData
  };
};
