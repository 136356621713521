import { UseQueryOptions, useQuery } from "react-query";
import { HTTPError, SiteListResponse } from "../../../types";
import { CommonDataApi } from "../../..";
import { sitesOfApplication } from "./query-cache";

/**
 * Hook to get a list of sites by application id
 */
export const useGetSitesByApplicationId = (applicationId: string) => {
  const queryOptions: UseQueryOptions<SiteListResponse, HTTPError> = {
    queryKey: sitesOfApplication(applicationId),
    queryFn: () => CommonDataApi.Applications.getSitesByApplicationId(applicationId)
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery<SiteListResponse, Error>(
    queryOptions
  );

  return {
    sites: data ? data.data : [],
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchSites: refetch
  };
};
