import * as React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormGroup } from "@mui/material";
import {
  RenderLabelValue,
  TextField,
  Select,
  AlphaNumericKeyField,
  TagsField,
  ToggleButtonGroup,
  ApplicationSelect,
  SiteOfApplicationSelect,
  SiteLocationSelect,
  WithLoader
} from "@packages/theme-mui-v5";
import { ListOfValuesSchema, Location, useGetLocationById } from "@packages/service-api";

interface SchemaFormProps {
  schema: ListOfValuesSchema | Partial<ListOfValuesSchema>;
  onSubmit?: (formFields: unknown) => void;
  onClose?: () => void;
  isEditForm: boolean;
}

export type SchemaFormRef = {
  onSubmit: () => void;
};

type FormFields = Partial<ListOfValuesSchema> & {
  location?: Location;
  locationType?: "all" | "single";
  siteId?: string;
  timeRange?: string | number;
};

type FormAllFields = Partial<ListOfValuesSchema> & {
  timeRange?: string | number;
};

const SchemaForm = React.forwardRef<SchemaFormRef, SchemaFormProps>((props, ref) => {
  const { schema, onSubmit, isEditForm } = props;

  const { location, loading: locationLoading } = useGetLocationById(schema?.locationId);

  const validationSchema = React.useMemo(() => {
    return isEditForm
      ? yup.object().shape({
          schemaName: yup.string()
        })
      : yup.object().shape({
          schemaName: yup.string().required("Schema name is required"),
          schemaType: yup.string().required("Schema name is required")
        });
  }, []);

  const getDefaultValues = React.useCallback(
    (schema?: FormAllFields): FormFields => {
      return {
        applicationId: schema?.applicationId,
        locationId: schema?.locationId || null,
        locationType: schema?.locationId ? "single" : "all",
        schemaKey: schema ? schema.schemaKey : "",
        schemaName: schema ? schema.schemaName : "",
        schemaType: schema ? schema.schemaType : "structured-data",
        siteId: "",
        tags: schema?.tags || [],
        settings: schema?.settings,
        timeRange: schema?.settings?.find((data) => data.key === "timeRange")?.value
      };
    },
    [isEditForm]
  );

  const { control, handleSubmit, reset, setValue, watch } = useForm<FormFields>({
    defaultValues: getDefaultValues(schema),
    resolver: yupResolver(validationSchema)
  });

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  React.useEffect(() => {
    reset(getDefaultValues(schema));
  }, [schema]);

  React.useEffect(() => {
    if (location) {
      setValue("location", location);
      setValue("siteId", location.siteId);
    }
  }, [location]);

  const settings = watch("settings");
  const locationType = watch("locationType");
  const applicationId = watch("applicationId");
  const siteId = watch("siteId");
  const schemaType = watch("schemaType");
  const timeRange = watch("timeRange");

  React.useEffect(() => {
    if (schemaType === "manual-entry") {
      setValue("locationType", "single");
    }
  }, [locationType, schemaType]);

  React.useEffect(() => {
    if (schemaType === "manual-entry") {
      const settingsData = settings?.length
        ? settings?.map((data) => {
            if (data.key === "timeRange") {
              return { ...data, value: timeRange };
            }
            return data;
          })
        : [{ key: "timeRange", value: timeRange || "1Day-Hourly" }];

      setValue("settings", settingsData);
      if (!timeRange) {
        setValue("timeRange", "1Day-Hourly");
      }
    }
  }, [schemaType, timeRange]);

  const loading = locationLoading;

  return (
    <WithLoader loading={loading}>
      <FormGroup onSubmit={handleSubmit(onSubmit)}>
        {schema && <RenderLabelValue label="Schema Id" value={schema.schemaId} />}
        <AlphaNumericKeyField
          control={control}
          disabled={!!schema}
          label="Key"
          name="schemaKey"
          setValue={setValue}
          sourceFieldName="schemaName"
          watch={watch}
          required
          viewValueByDefault={true}
        />
        <TextField
          name="schemaName"
          label="Name"
          control={control}
          required
          disabled={isEditForm}
        />
        <TagsField name="tags" label="Tags" control={control} />
        <Select
          options={[
            { label: "Structured Data", value: "structured-data" },
            { label: "Manual Entry", value: "manual-entry" }
          ]}
          labelKey="label"
          valueKey="value"
          name="schemaType"
          label="Type"
          control={control}
          required
        />

        {schemaType === "manual-entry" && (
          <Box pt={2}>
            <ToggleButtonGroup
              options={[
                { label: "Daily by hour", value: "1Day-Hourly" },
                { label: "Monthly by day", value: "1Month-Daily" }
              ]}
              name="timeRange"
              label="Data entry frequency"
              control={control}
            />
          </Box>
        )}
        {schemaType === "structured-data" && (
          <Box pt={2}>
            <ToggleButtonGroup
              options={[
                { label: "All", value: "all" },
                { label: "Single", value: "single" }
              ]}
              name="locationType"
              label="Location"
              control={control}
            />
          </Box>
        )}

        {locationType === "single" && (
          <>
            <ApplicationSelect name="applicationId" control={control} />
            {Boolean(applicationId) && (
              <SiteOfApplicationSelect
                applicationSelected={applicationId}
                control={control}
                name="siteId"
              />
            )}
            {Boolean(siteId && applicationId) && (
              <SiteLocationSelect
                applicationId={applicationId}
                control={control}
                siteId={siteId}
                selected={location}
                name="location"
                label="Location"
              />
            )}
          </>
        )}
      </FormGroup>
    </WithLoader>
  );
});

SchemaForm.displayName = "SchemaForm";

export default SchemaForm;
