import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi } from "../../..";
import { ApplicationsListResponse, HTTPError } from "../../../types";
import { applicationById, applicationsList } from "./query-cache";

/**
 * Hook to delete an application
 */
export const useDeleteApplication = () => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (applicationId: string) => CommonDataApi.Applications.delete(applicationId),
    onSuccess: (_application, applicationId) => {
      queryClient.setQueryData<ApplicationsListResponse | undefined>(
        applicationsList(),
        (currentState: ApplicationsListResponse | undefined) => {
          if (!currentState) return undefined;

          // Delete
          const nextApplications = currentState.data.filter((state) => {
            return state.applicationId !== applicationId;
          });

          return {
            ...currentState,
            data: [...nextApplications]
          };
        }
      );

      queryClient.invalidateQueries(applicationById(applicationId));
    }
  };
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    application: data,
    loading: isLoading,
    isError,
    isSuccess,
    deleteApplication: mutateAsync
  };
};
