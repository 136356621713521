import { FC } from "react";
import { PageContainer } from "@packages/theme-mui-v5";

import { WithPermissions } from "@packages/service-api";
import { useParams } from "react-router-dom";
import { RenderSchema } from "../../../RenderSchema";

type Props = {
  applicationId: string;
  permissions?: string | string[];
  returnPath: string;
};

export const ViewSheet: FC<Props> = ({ applicationId, permissions, returnPath }) => {
  const { idOrKey } = useParams<{ idOrKey: string }>();

  const renderContent = () => (
    <PageContainer title="View Sheet" hideLocationbar>
      <RenderSchema returnPath={returnPath} schemaIdOrKey={idOrKey} supportedViewModes={["grid"]} />
    </PageContainer>
  );
  return permissions ? (
    <WithPermissions applicationId={applicationId} permissions={permissions}>
      {renderContent()}
    </WithPermissions>
  ) : (
    renderContent()
  );
};
