import React from "react";
import { Paper } from "@mui/material";
import { ListOfValuesSchema, ManualEntry, ManualEntryFieldValues } from "@packages/service-api";
import {
  AgGridReactProps,
  CellRange,
  ColDef,
  DataGrid,
  NewValueParams,
  RangeSelectionChangedEvent
} from "@packages/data-grid";
import { generateTransposedGridConfig } from "../rows.helper";
import { View } from "../..";

type Props = {
  schema: ListOfValuesSchema;
  manualEntires: ManualEntry[];
  view: View;
  setSelectedCells: (data) => void;
  setUpdates: (data) => void;
  updates?: ManualEntryFieldValues[];
  read: boolean;
  write: boolean;
};

export const gridRef = React.createRef<AgGridReactProps>();

const ManualEntryDataGrid: React.FC<Props> = (props) => {
  const { schema, manualEntires, view, setSelectedCells, setUpdates, updates, write } = props;

  const { columnDefs, rowData } = React.useMemo(() => {
    return generateTransposedGridConfig(schema, manualEntires, view, write);
  }, [schema, manualEntires, view, write]);

  const getRowStyle = React.useCallback((params) => {
    return { backgroundColor: params.data?.isGroup ? "#f9f9f9" : "transparent" };
  }, []);

  const defaultColDef: ColDef = React.useMemo(() => {
    return {
      minWidth: 50,
      sortable: false,
      suppressMovable: true,
      editable: write
    };
  }, [write]);

  const onCellValueChanged = (params: NewValueParams) => {
    setUpdates((preState) => {
      const newData = params.data[params.colDef.field];
      const newState = preState.filter(
        (data) => data.timeStamp !== newData.timeStamp || data.key !== newData.key
      );
      return [...newState, newData];
    });
  };

  const onRangeSelectionChanged = ({ api }: RangeSelectionChangedEvent) => {
    if (updates?.length) {
      gridRef?.current?.api?.clearRangeSelection();
      return;
    }
    const cellRanges = api.getCellRanges();
    const list = [];
    if (cellRanges) {
      cellRanges.forEach((range: CellRange) => {
        // get starting and ending row, remember rowEnd could be before rowStart
        const startRow = Math.min(range.startRow?.rowIndex, range.endRow?.rowIndex);
        const endRow = Math.max(range.startRow?.rowIndex, range.endRow?.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          range.columns.forEach((column) => {
            if (column.getColId().includes("col-")) {
              const rowModel = api.getModel();
              const rowNode = rowModel.getRow(rowIndex);
              const data = rowNode.data[column.getColDef().field];
              if (data && !data?.isGroup && data?.isEditableCell) {
                list.push({ ...data, dataType: rowNode.data.dataType });
              }
            }
          });
        }
      });
    }
    // set unique cells only as sometime ag-grid provide duplicate cells
    setSelectedCells([
      ...new Map(list.map((item) => [`${item.timeStamp}-${item.key}`, item])).values()
    ]);
  };

  return columnDefs ? (
    <DataGrid
      getRowStyle={getRowStyle}
      gridRef={gridRef}
      onRangeSelectionChanged={onRangeSelectionChanged}
      autoSizeAllColumnsOnDenseMode
      columnDefs={columnDefs}
      rowData={rowData}
      defaultColDef={defaultColDef}
      stopEditingWhenCellsLoseFocus={true}
      singleClickEdit={true}
      suppressRowClickSelection
      onCellValueChanged={onCellValueChanged}
      enableHeaderHighlightOnCellFocus
      enableRangeSelection={write}
      suppressRowHoverHighlight
      overlayNoRowsTemplate={"<span>No Manual Entry data found.</span>"}
    />
  ) : (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        minHeight: 300
      }}
    >
      No manual entry exist at this site
    </Paper>
  );
};

export default ManualEntryDataGrid;
