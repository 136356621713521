import { Box, Typography } from "@mui/material";
import { LayoutGroup, ListOfValuesField } from "@packages/service-api";
import { FormApi, ReactFormApi } from "@tanstack/react-form";
import DynamicFormItem from "./DynamicFormItem";

export type DynamicFormGroupProps = {
  form: FormApi<any, any> & ReactFormApi<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  group: LayoutGroup;
  fields: Array<ListOfValuesField>;
};

export default function DynamicFormGroup({ form, group, fields }: DynamicFormGroupProps) {
  return (
    <Box key={group.layoutId}>
      {group.name && (
        <Box sx={{ my: 2 }}>
          <Typography variant="h5">
            <b>{group.name}</b>
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {group.layout.map((item) => {
          return <DynamicFormItem key={item.layoutId} form={form} item={item} fields={fields} />;
        })}
      </Box>
    </Box>
  );
}
