import { QueryKey } from "react-query";

type QueryKeyFunction = (...args: unknown[]) => QueryKey;

export const schemasSingleQueryKey: QueryKeyFunction = (idOrKey: string) => [
  "list-of-values",
  "schemas",
  "single",
  idOrKey
];

export const schemaItemsQueryKey: QueryKeyFunction = (idOrKey: string) => [
  "list-of-values",
  "schemas",
  "single",
  idOrKey,
  "items"
];

export const singleSchemaItemQueryKey: QueryKeyFunction = (idOrKey: string, itemId: string) => [
  "list-of-values",
  "schemas",
  "single",
  idOrKey,
  "items",
  itemId
];

export const listSchemaItemsByIdOrKeyQueryKey: QueryKeyFunction = (idOrKey: string) => [
  "list-of-values",
  "schemas",
  idOrKey,
  "items"
];
