import * as React from "react";
import { Box, FormControl, MenuItem, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useMediaQuery } from "../../hooks";
import { getMonths } from "@packages/utils";

interface Props {
  defaultValue?: number;
  maxMonth?: number;
  onChange: (month: number) => void;
  value: number;
}

const MonthSelection = (props: Props) => {
  const { maxMonth, onChange, value } = props;
  const months: string[] = getMonths(undefined, maxMonth);
  const { smUp, smDown } = useMediaQuery();

  const handleMonthChange = (nextMonth: number) => {
    onChange(nextMonth);
  };

  const nextMonth = () => {
    handleMonthChange(value + 1);
  };

  const previousMonth = () => {
    handleMonthChange(value - 1);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "middle",
        justifyContent: "space-between",
        width: smDown ? "100%" : 180,
        mt: smDown && theme.spacing(2)
      })}
    >
      {smUp && (
        <IconButton
          size="small"
          color="primary"
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            }
          }}
          aria-label="previous month"
          component="label"
          onClick={previousMonth}
          disabled={value === 1}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}

      <Box sx={{ width: "100%", minWidth: 126 }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel>Month</InputLabel>
          <Select
            value={String(value)}
            size="small"
            label="Month"
            sx={{ width: "100%" }}
            onChange={(event: SelectChangeEvent) => handleMonthChange(Number(event.target.value))}
          >
            {months &&
              months.map((month, index) => (
                <MenuItem key={index} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {smUp && (
        <IconButton
          disabled={value === 12 || value === maxMonth}
          color="primary"
          aria-label="next month"
          component="label"
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            }
          }}
          onClick={nextMonth}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default MonthSelection;
