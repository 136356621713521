import { Application } from "@nutrien-operations/config";
import { PageContainer } from "@packages/theme-mui-v5";

import { FC } from "react";
import { WithPermissions } from "@packages/service-api";
import { ViewSheetsGrid } from "../../../components/items/manual-entry";
import InternalNavigationProvider from "../../../components/InternalNavigationProvider";

type Props = {
  appConfig: Application;
  basePath?: string;
  permissions?: string | string[];
};

export const ViewSheets: FC<Props> = ({ appConfig, basePath, permissions }) => {
  const renderContent = () => (
    <InternalNavigationProvider basePath={basePath}>
      <PageContainer title="Sheets">
        <ViewSheetsGrid appConfig={appConfig} />
      </PageContainer>
    </InternalNavigationProvider>
  );

  return permissions ? (
    <WithPermissions applicationId={appConfig.applicationId} permissions={permissions}>
      {renderContent()}
    </WithPermissions>
  ) : (
    renderContent()
  );
};
