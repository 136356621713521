import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";

import { ListOfValuesFieldType, SchemaType } from "@packages/service-api";

import { booleanFieldSchema } from "./BooleanFieldFields";
import { determineBaseFieldSchema } from "./BaseFieldFields";

export const getFieldSchemaFormResolver = (
  fieldType: ListOfValuesFieldType,
  schemaType: SchemaType
): Resolver => {
  const baseSchema = determineBaseFieldSchema(schemaType);

  let schema = baseSchema;

  switch (fieldType) {
    case "boolean":
      schema = baseSchema.concat(booleanFieldSchema);
      break;
    case "number":
    case "string":
    default:
      schema = baseSchema;
  }

  return yupResolver(schema);
};
