import { LayoutField, LayoutGroup, ListOfValuesField } from "@packages/service-api";
import DynamicFormField from "./DynamicFormField";
import DynamicFormGroup from "./DynamicFormGroup";
import { FormApi, ReactFormApi } from "@tanstack/react-form";

export type DynamicFormItemProps = {
  form: FormApi<any, any> & ReactFormApi<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  item: LayoutGroup | LayoutField;
  fields: Array<ListOfValuesField>;
};

export default function DynamicFormItem({ form, item, fields }: DynamicFormItemProps) {
  if (item.type === "group") {
    return <DynamicFormGroup form={form} group={item} fields={fields} />;
  } else {
    return <DynamicFormField form={form} field={item} fields={fields} />;
  }
}
