import { forwardRef, MutableRefObject, RefObject, useImperativeHandle, useMemo } from "react";
import { FormApi, ReactFormApi, useForm } from "@tanstack/react-form";
import { yupValidator } from "@tanstack/yup-form-adapter";
import { ListOfValuesItem, ListOfValuesSchema } from "@packages/service-api";
import { getDynamicFormProps } from "./DynamicFormUtils";
import DynamicFormItem from "./DynamicFormItem";

export type DynamicFormApi = FormApi<any, any> & ReactFormApi<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * Allows parent components to access the `form` instance for handling submit from outside the `form` DOM element.
 */
export type DynamicFormRef = RefObject<{ form: DynamicFormApi }>;

export type DynamicFormValues = Record<string, string | string[] | number | boolean>;

export type DynamicFormProps<FormValues extends DynamicFormValues> = {
  onSubmit: (values: FormValues) => void;
  schema: ListOfValuesSchema;
  item?: ListOfValuesItem;
};

const DynamicFormForwarded = forwardRef(function DynamicForm<FormValues extends DynamicFormValues>(
  { item, schema, onSubmit }: DynamicFormProps<FormValues>,
  ref?: MutableRefObject<{ form: DynamicFormApi }>
) {
  const { defaultValues } = useMemo(() => getDynamicFormProps(schema.fields, item), [schema, item]);

  const form = useForm({
    defaultValues,
    onSubmit: ({ value }) => onSubmit(value as FormValues),
    validatorAdapter: yupValidator()
  });

  // allow parent components access to `form` via `ref`
  useImperativeHandle(ref, () => ({ form }));

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      {schema.layout.form.map((item, index) => {
        return <DynamicFormItem key={index} form={form} item={item} fields={schema.fields} />;
      })}
    </form>
  );
});

DynamicFormForwarded.displayName = "DynamicForm";

export default DynamicFormForwarded;
