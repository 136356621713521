import * as React from "react";
import * as yup from "yup";
import { Alert, FormGroup } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ApplicationSelect,
  ApplicationPermissionSelect,
  SiteLocationSelect,
  SiteSelect
} from "@packages/theme-mui-v5";
import { Resolver, useForm } from "react-hook-form";
import {
  Auth,
  ListOfValuesSchemaPermission,
  Location,
  useGetPermissionsByApplicationId
} from "@packages/service-api";

interface PermissionForm {
  onSubmit: (permission: ListOfValuesSchemaPermission) => void;
  schemaPermissions: ListOfValuesSchemaPermission[];
}

export type ListOfValuesSchemaPermissionForm = ListOfValuesSchemaPermission & {
  siteId?: string;
  location?: Location;
};

export type PermissionFormRef = {
  onSubmit: () => void;
};

const permissionSchema = yup.object<ListOfValuesSchemaPermissionForm>().shape({
  permissionTypeId: yup.string().required("Permission is required."),
  applicationId: yup.string().required("Application is required."),
  siteId: yup.string().required("Site is required."),
  location: yup.object().required("Location is required.")
});

const PermissionForm = React.forwardRef<PermissionFormRef, PermissionForm>((props, ref) => {
  const { onSubmit, schemaPermissions } = props;

  const { handleSubmit, control, watch } = useForm<ListOfValuesSchemaPermissionForm>({
    resolver: yupResolver(permissionSchema) as unknown as Resolver<ListOfValuesSchemaPermissionForm>
  });

  const applicationId = watch("applicationId");
  const siteId = watch("siteId");
  const location = watch("location");
  const permissionTypeId = watch("permissionTypeId");

  const { permissions } = useGetPermissionsByApplicationId(applicationId);

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  const updateOptions = React.useCallback(
    (permissions) => {
      if (permissions?.length) {
        return permissions.map((item) => ({
          ...item,
          disabled: !!schemaPermissions.find(
            (data) => data.permissionTypeId === item.id && data.locationId === location.locationId
          )
        }));
      }
      return [];
    },
    [schemaPermissions, location]
  );

  const permissionName = React.useMemo(() => {
    if (
      applicationId &&
      permissionTypeId &&
      siteId &&
      location &&
      permissions &&
      permissions?.length &&
      !Auth.checkPermission({
        applicationId,
        permissions: [permissionTypeId],
        siteId,
        locationId: location?.locationId
      })
    ) {
      return permissions.find((data) => data.permissionTypeId === permissionTypeId)?.name;
    }
  }, [permissionTypeId, permissions, Auth, applicationId, siteId, location]);

  return (
    <>
      {!!permissionName && (
        <Alert severity="warning">
          You do not have the selected permission: <b>{permissionName}</b>. Adding this permission
          will cause you to no longer have the ability to make changes to this schema
        </Alert>
      )}
      <FormGroup onSubmit={handleSubmit(onSubmit)}>
        <ApplicationSelect name="applicationId" control={control} required />
        {Boolean(applicationId) && <SiteSelect name="siteId" control={control} required />}
        {Boolean(siteId && applicationId) && (
          <SiteLocationSelect
            applicationId={applicationId}
            siteId={siteId}
            name="location"
            label="Location"
            control={control}
            allowAnyLocationSelection
            required
          />
        )}
        {Boolean(applicationId && siteId && location) && (
          <ApplicationPermissionSelect
            name="permissionTypeId"
            updateOptions={updateOptions}
            applicationId={applicationId}
            control={control}
            required
          />
        )}
      </FormGroup>
    </>
  );
});

PermissionForm.displayName = "PermissionForm";

export default PermissionForm;
