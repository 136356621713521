import React from "react";
import { Outlet } from "react-router-dom";
import { WithLoader } from "@packages/theme-mui-v5";
import { ListOfValuesRenderType, useGetSchemaByIdOrKey } from "@packages/service-api";
import FormModeRenderer from "./FormModeRenderer";
import SchemaToolbar from "./SchemaToolbar";
import { Box } from "@mui/material";
import GridViewRenderer from "./GridView/GridViewRenderer";

export type View = "1Month-Daily" | "1Day-Hourly";

type Props = {
  schemaIdOrKey: string;
  returnPath?: string;
  hideToolbar?: boolean;
  defaultViewMode?: ListOfValuesRenderType;
  supportedViewModes: ListOfValuesRenderType[];
};

export const RenderSchema: React.FC<Props> = (props) => {
  const { schemaIdOrKey, hideToolbar, returnPath, defaultViewMode, supportedViewModes } = props;

  React.useEffect(() => {
    if (defaultViewMode && !supportedViewModes.includes(defaultViewMode)) {
      throw new Error(
        "The default view mode provided does not exist in the supportedViewModes provided"
      );
    }
    if (!defaultViewMode && supportedViewModes.length > 1) {
      throw new Error(
        "A default view mode must be provided when more than one supported view mode is provided"
      );
    }
  }, [defaultViewMode, supportedViewModes]);

  const { schema, loading } = useGetSchemaByIdOrKey(schemaIdOrKey);

  // const { read, write } = useGetSchemaPermissions(schema);

  const [viewMode, setViewMode] = React.useState<ListOfValuesRenderType>(
    defaultViewMode || supportedViewModes[0]
  );

  return (
    <Box>
      {schema && (
        <>
          {!hideToolbar && (
            <SchemaToolbar
              schema={schema}
              supportedViewModes={supportedViewModes}
              returnPath={returnPath}
              viewMode={viewMode}
              onViewModeChange={setViewMode}
            />
          )}
          <Box
            sx={{
              position: "relative",
              backgroundColor: loading ? "background.paper" : "inherit",
              minHeight: 300
            }}
          >
            <WithLoader loading={loading}>
              {viewMode === "grid" && <GridViewRenderer schema={schema} />}
              {viewMode === "form" && <FormModeRenderer schema={schema} />}
            </WithLoader>
          </Box>
        </>
      )}

      <Outlet />
    </Box>
  );
};
