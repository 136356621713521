import React from "react";
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  IconButton,
  InputAdornment,
  Tooltip,
  Menu,
  MenuItem
} from "@mui/material";
import { Control, Controller, UseFormSetValue, UseFormWatch, useFieldArray } from "react-hook-form";
import { FormControl } from "@mui/material";
import TextField from "./TextField";
import { ApplicationIcon } from "../icons";
import { locales } from "@packages/service-api";

type LocaleMenuProps = {
  exclude: string[];
  onChange: (locale: string) => void;
  value: string;
};

const LocaleMenu: React.FC<LocaleMenuProps> = (props) => {
  const { exclude, onChange, value } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onMenuItemClick = (locale: string) => {
    onChange(locale);

    handleClose();
  };

  const localesToDisplay = React.useMemo(() => {
    return locales.filter((l) => !exclude.includes(l));
  }, [exclude]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // only open the menu when
    // 1. The menu is not for the first item because we always want the first item to be en_us
    // 2. there are locales to display. localesToDisplay will be zero
    //    when there is already a translation for each available locale
    if (value !== "en_us" && localesToDisplay.length !== 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <div>
      <Tooltip title="Translation Locale">
        <Chip
          size="small"
          label={value}
          id="locale-button"
          aria-controls={open ? "locale-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        />
      </Tooltip>
      <Menu
        id="locale-positioned-menu"
        aria-labelledby="locale-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {localesToDisplay.map((locale) => (
          <MenuItem key={locale} onClick={() => onMenuItemClick(locale)}>
            {locale}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
};

const TextFieldWithTranslations: React.FC<Props> = (props) => {
  const { disabled, control, helperText, label, name, setValue, watch } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  const fieldValue = watch(name);

  const showAddButton = fields.length < locales.length;

  const existingLocales = React.useMemo(() => {
    return fieldValue.map((v) => v.locale);
  }, [fieldValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState }) => (
        <FormControl sx={{ width: "100%" }}>
          {fields.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <TextField
                sx={{
                  // We only want to set this margin in the last translation field
                  // first check ensures we are targeting the last field in the fields array
                  // second ensures that the padding is set correctly based on if we are showing the add button
                  mb: index === fields.length - 1 && showAddButton ? 0 : 2
                }}
                control={control}
                label={index === 0 ? label : undefined}
                disabled={disabled}
                helperText={helperText}
                name={`${name}.${index}.value`}
                endAdornment={
                  <InputAdornment position="end" sx={{ my: 2, mr: 1 }}>
                    <LocaleMenu
                      exclude={existingLocales}
                      onChange={(locale) => setValue(`${name}.${index}.locale`, locale)}
                      value={watch(`${name}.${index}.locale`, "")}
                    />
                  </InputAdornment>
                }
              />
              {index > 0 && (
                <Tooltip title="Delete Translation">
                  <IconButton
                    onClick={() => remove(index)}
                    size="small"
                    sx={(theme) => ({
                      "&:hover": {
                        background: "none",
                        "&> svg": {
                          color: theme.palette.error.main
                        }
                      }
                    })}
                  >
                    <ApplicationIcon name="delete" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ))}
          {showAddButton && (
            <Box sx={{ mb: 2 }}>
              <Button
                sx={(theme) => ({
                  py: 0,
                  fontSize: theme.typography.fontSize * 0.8
                })}
                size="small"
                onClick={() => {
                  const nextLocale = locales.find((locale) => !existingLocales.includes(locale));
                  append({ value: "", locale: nextLocale });
                }}
              >
                Add Translation
              </Button>
            </Box>
          )}
          {(fieldState.error?.message || helperText) && (
            <FormHelperText>{fieldState.error?.message || helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default TextFieldWithTranslations;
