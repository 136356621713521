import React from "react";

import { ListOfValuesSchema } from "@packages/service-api";
import { ManualEntryRenderer } from "./manual-entry/ManualEntryRenderer";
import StructuredDataRenderer from "./structured-data/StructuredDataRenderer";

type Props = {
  schema: ListOfValuesSchema;
};

const GridViewRenderer: React.FC<Props> = (props) => {
  const { schema } = props;

  if (schema.schemaType === "manual-entry") {
    return <ManualEntryRenderer schema={schema} />;
  }

  return <StructuredDataRenderer schema={schema} />;
};

export default GridViewRenderer;
