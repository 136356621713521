import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";

import { ViewSheets, ViewSheet } from "@packages/list-of-values";

import appConfig from "./config";

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route
          path="sheets"
          element={
            <ViewSheets
              appConfig={appConfig}
              permissions={appConfig.permissions.configureApplication}
            />
          }
        />
        <Route
          path="sheets/:idOrKey"
          element={
            <ViewSheet
              returnPath="/manual-entry/sheets"
              applicationId={appConfig.applicationId}
              permissions={appConfig.permissions.configureApplication}
            />
          }
        />
        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route path="*" element={<Navigate to="sheets" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
