import React from "react";
import { Box, Checkbox, FormControlLabel, FormLabel, Tooltip } from "@mui/material";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { ApplicationIcon } from "@packages/theme-mui-v5";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
  isEditForm: boolean;
  isClone?: boolean;
};

const SchemaFormField = (props: Props) => {
  const { setValue, watch, isEditForm, isClone } = props;

  const daysHours = React.useMemo(() => [...Array(24).keys()], []);
  const expected = watch("manualEntry.expected") || [];
  const notExpected = watch("manualEntry.notExpected") || [];
  const notAllowed = watch("manualEntry.notAllowed") || [];

  const onListUpdate = (currentValue: string, option: number) => {
    if (currentValue === "Expected") {
      setValue(
        "manualEntry.expected",
        expected.filter((item: number) => item !== option)
      );
      setValue("manualEntry.notExpected", [...notExpected, option].sort());
    } else if (currentValue === "Not Expected") {
      setValue(
        "manualEntry.notExpected",
        notExpected.filter((item: number) => item !== option)
      );
      setValue("manualEntry.notAllowed", [...notAllowed, option].sort());
    } else if (currentValue === "Not Allowed") {
      setValue(
        "manualEntry.notAllowed",
        notAllowed.filter((item: number) => item !== option)
      );
      setValue("manualEntry.expected", [...expected, option].sort());
    }
  };

  React.useEffect(() => {
    if (!isEditForm && !isClone) {
      setValue("manualEntry.expected", daysHours);
    }
  }, [isEditForm, daysHours]);

  return (
    <Box>
      <FormLabel
        sx={(theme) => ({
          fontWeight: 600,
          fontSize: 12,
          pb: 1,
          color: theme.palette.text.primary
        })}
      >
        Allow Editing
      </FormLabel>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {daysHours.map((option) => {
          const isExpected = expected.includes(option) && "Expected";
          const isNotExpected = notExpected.includes(option) && "Not Expected";
          const isNotAllowed = notAllowed.includes(option) && "Not Allowed";
          const value = isExpected || isNotExpected || isNotAllowed || "Expected";
          return (
            <Tooltip title={value} key={option} enterDelay={2000}>
              <FormControlLabel
                sx={{ width: 54 }}
                control={
                  <Checkbox
                    size="small"
                    value={value}
                    indeterminateIcon={
                      <ApplicationIcon color="error" size="large" name="x-square" />
                    }
                    checked={!isNotExpected}
                    indeterminate={!!isNotAllowed}
                    onChange={() => onListUpdate(value, option)}
                  />
                }
                label={option}
                labelPlacement="end"
              />
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};

export default SchemaFormField;
