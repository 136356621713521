import { UseQueryOptions, useQuery } from "react-query";
import { ManualEntryApi } from "../../../apis/online-log-sheets/manual-entry";
import { HTTPError, ManualEntryListResponse } from "../../../types";
import { manualEntryListQueryKey } from "./query-cache";

export const useGetManualEntries = (sheetIdOrKey: string, startDate: string) => {
  const queryOptions: UseQueryOptions<ManualEntryListResponse, HTTPError> = {
    enabled: Boolean(sheetIdOrKey && startDate),
    queryKey: manualEntryListQueryKey(sheetIdOrKey, startDate),
    queryFn: () => ManualEntryApi.Entries.get(sheetIdOrKey, startDate)
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(queryOptions);

  return {
    manualEntires: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchManualEntires: refetch
  };
};
