export const applicationsList = () => ["common-data", "applications", "list"];

export const applicationById = (applicationId: string) => [
  "common-data",
  "applications",
  "single",
  applicationId
];

export const sitesOfApplication = (applicationId: string) => [
  "common-data",
  "sites",
  "by-applicationId",
  applicationId
];
