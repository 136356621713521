import { ReactNode, useId } from "react";
import { MarkdownEditor } from "@packages/markdown";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

export type MarkdownFieldProps = {
  label?: ReactNode;
  helperText?: ReactNode;
  required?: boolean;
  error?: boolean;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  autoFocus?: boolean;

  markdownHeight?: string;
};

export default function MarkdownField({
  label,
  error,
  required,
  helperText,
  value,
  onChange,
  onBlur,
  autoFocus,
  markdownHeight
}: MarkdownFieldProps) {
  const inputId = useId();
  const descriptionId = useId();
  return (
    <FormControl required={required} error={error} variant="standard">
      <InputLabel htmlFor={inputId} required={required} shrink style={{ position: "initial" }}>
        {label}
      </InputLabel>
      <MarkdownEditor
        id={inputId}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus={autoFocus}
        height={markdownHeight}
      />
      <FormHelperText id={descriptionId}>{helperText}</FormHelperText>
    </FormControl>
  );
}
