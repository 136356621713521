import * as React from "react";

import { FieldValues } from "react-hook-form";
import { useGetSitesByApplicationId } from "@packages/service-api";
import { WithLoader } from "../WithLoader";
import SiteSelect from "./SiteSelect";
import { SelectElementProps } from "./Select";
type Props = {
  applicationSelected: string;
};

function SiteOfApplicationSelect<TFieldValues extends FieldValues>(
  props: SelectElementProps<TFieldValues> & Props
): JSX.Element {
  const { sites, loading } = useGetSitesByApplicationId(props.applicationSelected);

  const siteOptions = React.useMemo(() => {
    if (sites.length) {
      return sites.map((item) => ({
        id: item.siteId,
        value: item.siteId,
        label: item.siteName
      }));
    }
    return [];
  }, [sites]);

  return (
    <WithLoader loading={loading}>
      <SiteSelect
        options={siteOptions}
        sortOptions
        name={props.name}
        label={props.label || "Sites"}
        labelKey="label"
        valueKey="value"
        {...props}
      />
    </WithLoader>
  );
}

export default SiteOfApplicationSelect;
