import React, { FC, useMemo, useRef } from "react";
import { EnsureLocationSelected, WithLoader } from "@packages/theme-mui-v5";
import {
  DataGrid,
  ColDef,
  AgGridReactProps,
  RowActionsCell,
  ICellRendererParams
} from "@packages/data-grid";

import {
  ListOfValuesSchema,
  useGetSchemaPermissions,
  useSelectedLocation,
  useSheetsByLocationId
} from "@packages/service-api";
import { Application } from "@nutrien-operations/config";
import { useInternalNavigate } from "../../InternalNavigationProvider";

const ActionRenderer = (params: ICellRendererParams) => {
  const navigate = useInternalNavigate();
  const { read, write, configure } = useGetSchemaPermissions(params.data);
  const actions = [];

  if (read || write) {
    actions.push({
      action: () => navigate(`/sheets/${params?.data?.schemaId}`),
      color: "primary",
      icon: "table",
      tooltip: write ? "View or Edit" : "View"
    });
  }

  if (configure) {
    actions.push({
      action: () => {
        window.open(
          `${location.origin}/list-of-values/lov/schemas/${params.data?.schemaId}`,
          "_blank"
        );
      },
      color: "primary",
      icon: "settings",
      tooltip: "Configure"
    });
  }

  return <RowActionsCell actions={actions} />;
};
type Props = {
  appConfig: Application;
};

export const ViewSheetsGrid: FC<Props> = ({ appConfig }) => {
  const { sheets, loading } = useSheetsByLocationId(appConfig?.applicationId);

  const { siteId, selectedLocation } = useSelectedLocation(appConfig.applicationId);

  const gridRef = useRef<AgGridReactProps<ListOfValuesSchema>>(null);

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: "Name",
        field: "schemaName",
        tooltipField: "Name",
        sort: "asc"
      },
      {
        headerName: "",
        maxWidth: 80,
        minWidth: 80,
        cellRenderer: ActionRenderer,
        sortable: false
      }
    ];
  }, [sheets, siteId, selectedLocation]);

  const gridOptions = useMemo(() => {
    return {
      pagination: true
    };
  }, []);

  return (
    <EnsureLocationSelected applicationId={appConfig.applicationId}>
      <WithLoader loading={loading}>
        <DataGrid<ListOfValuesSchema>
          gridRef={gridRef}
          autoSizeAllColumns
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          rowData={sheets}
          overlayNoRowsTemplate={"<span>No manual entry sheets exist at this location</span>"}
        />
      </WithLoader>
    </EnsureLocationSelected>
  );
};
