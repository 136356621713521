import * as React from "react";
import Select, { SelectElementProps, Option } from "./Select";
import { FieldValues } from "react-hook-form";
import { useGetSites } from "@packages/service-api";
import { WithLoader } from "../WithLoader";

type Props<T = Option> = SelectElementProps<T> & {
  onOptionChange?: (option: T | undefined) => void;
};

function SiteSelect<TFieldValues extends FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const { sites, loading } = useGetSites(null, { size: 1000 });

  const sitesOptions = React.useMemo(() => {
    if (sites?.length) {
      return sites.map((item) => ({
        id: item.siteName,
        value: item.siteId,
        label: item.siteName
      }));
    }
    return [];
  }, [sites]);

  return (
    <WithLoader loading={loading}>
      <Select
        options={sitesOptions}
        sortOptions
        name={props.name}
        label={props.label || "Site"}
        labelKey="label"
        valueKey="value"
        {...props}
      />
    </WithLoader>
  );
}

export default SiteSelect;
