import { TextField, TextFieldProps } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { Control, Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

export type TextFieldElementProps<T> = Omit<TextFieldProps, "name"> & {
  name: Path<T>;
  control?: Control<T>;
};

function TagsField<TFieldValues extends FieldValues>({
  name,
  control,
  label
}: TextFieldElementProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => {
        return (
          <Autocomplete
            onChange={(e, value) => onChange(value)}
            multiple
            options={[]}
            defaultValue={value || []}
            value={value || []}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                variant="standard"
                error={invalid}
                label={label}
                fullWidth
                InputLabelProps={{ shrink: true }}
                helperText={error ? error.message : null}
                onBlur={onBlur}
              />
            )}
          />
        );
      }}
    />
  );
}

export default TagsField;
