import { useQuery } from "react-query";
import { ListOfValuesApi } from "../../..";
import { ListOfValuesItemListResponse } from "../../../types";
import { schemaItemsQueryKey } from "../query-cache";

export const useGetSchemaItems = (idOrKey: string) => {
  const { data, error, isLoading, isFetching } = useQuery<ListOfValuesItemListResponse, Error>({
    queryKey: schemaItemsQueryKey(idOrKey),
    enabled: !!idOrKey,
    queryFn: () => ListOfValuesApi.Items.getBySchemaId(idOrKey)
  });

  return {
    items: data?.data || [],
    error: error,
    loading: isLoading || isFetching
  };
};
