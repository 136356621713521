type Locale = "en_us" | "fr_ca" | "es_us" | "en_ca";
export const locales: Locale[] = ["es_us", "en_us", "fr_ca", "en_ca"];

type MultiLingualFieldProperty = {
  locale: Locale;
  value: string;
};

type MultiLingualFieldPropertyList = MultiLingualFieldProperty[];

type BaseField = {
  fieldKey: string;
  label?: MultiLingualFieldPropertyList;
  placeholder?: MultiLingualFieldPropertyList;
  type: string;
  isRequired?: boolean;
  defaultValue?: unknown;
  decimalPlaces?: number;
  manualEntry?: {
    units?: string;
    system: string;
    tagName: string;
    reductionType: "Raw" | "Snapshot" | "Average" | "Delta" | "Min" | "Max" | "StdDev"; //default raw
    reductionFrequency: number; //defaults to 0
    reductionOffset: "Before" | "After"; //defaults to before
    displayLastValue: boolean; // default false
    writable: boolean; // default true
    expected: number[];
    notExpected: number[];
    notAllowed: number[];
    tagType?: "new" | "existing";
  };
};

interface StringField extends BaseField {
  type: "string";
  defaultValue?: string | undefined;
}

interface StringArrayField extends BaseField {
  type: "string[]";
  defaultValue?: string[] | undefined;
}

interface NumberField extends BaseField {
  type: "number";
  defaultValue?: number | undefined;
}

interface BooleanField extends BaseField {
  type: "boolean";
  display: "checkbox" | "button-group";
  labels?: Record<"true" | "false", MultiLingualFieldProperty>; // for button group
}

interface MarkdownField extends BaseField {
  type: "markdown";
  defaultValue?: string | undefined;
}

interface MarkdownArrayField extends BaseField {
  type: "markdown[]";
  defaultValue?: string[] | undefined;
}

interface ReferenceField extends BaseField {
  type: "reference";
  attributeValueKey: string;
  attributeLabelKey: string;
  attributesToInclude: string;
}

interface BaseSelectField extends BaseField {
  type: "select";
  display: "checkboxes" | "dropdown";
  multiple?: boolean;
}

interface SelectFieldExternalOptions extends BaseSelectField {
  optionsUrl?: string;
  labelKey?: string;
  dataKey?: string;
}

interface SelectFieldLocalOptions extends BaseSelectField {
  options: (string | number)[];
}

export type SelectField = SelectFieldExternalOptions | SelectFieldLocalOptions;

export type ListOfValuesField =
  | BooleanField
  | MarkdownArrayField
  | MarkdownField
  | NumberField
  | ReferenceField
  | SelectField
  | StringArrayField
  | StringField;

export type ListOfValuesFieldType = ListOfValuesField["type"];
export type CreateListOfValuesFieldBody = Partial<ListOfValuesField>;
