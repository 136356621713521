import * as React from "react";
import { FormControl, MenuItem, Box, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useMediaQuery } from "../../hooks";
import { getYears } from "@packages/utils";

interface Props {
  defaultValue?: number;
  onChange: (year: number) => void;
  numberOfYears?: number;
}

const YearSelection = (props: Props) => {
  const { defaultValue, onChange, numberOfYears = 25 } = props;
  const { smDown, smUp } = useMediaQuery();

  const [selectedYear, setSelectedYear] = React.useState<number>(
    defaultValue || new Date().getFullYear()
  );

  const years: number[] = getYears(numberOfYears);

  React.useEffect(() => {
    onChange(selectedYear);
  }, [selectedYear]);

  const nextYear = () => {
    const index = years.indexOf(selectedYear);
    if (index > 0) {
      setSelectedYear(years[index - 1]);
    }
  };

  const previousYear = () => {
    const index = years.indexOf(selectedYear);
    if (index + 1 < years.length) {
      setSelectedYear(years[index + 1]);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "middle",
        justifyContent: "space-between",
        width: smDown ? "100%" : 180,
        mt: smDown && theme.spacing(2)
      })}
    >
      {smUp && (
        <IconButton
          color="primary"
          aria-label="previous year"
          component="label"
          onClick={previousYear}
          disabled={years.indexOf(selectedYear) >= 9}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            }
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}

      <Box sx={{ width: "100%" }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel>Year</InputLabel>
          <Select
            size="small"
            value={selectedYear ? selectedYear.toString() : ""}
            label="Year"
            className="item-override"
            sx={{ width: "100%" }}
            onChange={(event: SelectChangeEvent) => setSelectedYear(parseInt(event.target.value))}
          >
            {years &&
              years.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      {smUp && (
        <IconButton
          disabled={years.indexOf(selectedYear) === 0}
          color="primary"
          aria-label="next year"
          component="label"
          onClick={nextYear}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            }
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default YearSelection;
