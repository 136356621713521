import { UseQueryOptions, useQuery } from "react-query";

import { CommonDataApi, Site, QueryOptions, SiteListResponse, HTTPError } from "../../..";
import { sitesList } from "./query-cache";

/**
 * Get a list of sites
 */
export const useGetSites = (query: Partial<Site> = {}, queryOptions: QueryOptions = {}) => {
  const options: UseQueryOptions<SiteListResponse, HTTPError> = {
    queryKey: sitesList(),
    queryFn: () => CommonDataApi.Sites.get(query, queryOptions)
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(options);

  return {
    sites: data ? data.data : [],
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchSites: refetch
  };
};
