import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { FieldErrors, GlobalError } from "react-hook-form";

type Props = {
  errors: FieldErrors<unknown>;
};

const FormErrors: React.FC<Props> = ({ errors }) => {
  const formattedErrors = React.useMemo(() => {
    const errorStrings: string[] = [];

    const extractErrors = (errors: GlobalError[]) => {
      Object.values(errors).map((error) => {
        if (error?.message) {
          errorStrings.push(error?.message);
        }

        if (Array.isArray(error) || typeof error === "object") {
          extractErrors(error as GlobalError[]);
        }
      });
    };

    extractErrors(errors as GlobalError[]);

    return errorStrings;
  }, [errors]);

  return (
    <>
      {formattedErrors.length > 0 && (
        <Alert color="error" variant="outlined" sx={{ mb: 2 }}>
          <AlertTitle>Errors</AlertTitle>

          <ul>
            {formattedErrors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
};

export default FormErrors;
