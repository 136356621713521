import React, { useState } from "react";
import {
  Box,
  Button,
  TextField as MUITextField,
  StandardTextFieldProps,
  Typography
} from "@mui/material";
import { Control, Controller, Path, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { RenderLabelValue } from "../../nutrien-operations-theme-mui-v5";

interface Props extends StandardTextFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: Path<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  sourceFieldName: string;
  viewValueByDefault?: boolean;
  disabled?: boolean;
}

const AlphaNumericKeyField: React.FC<Props> = (props) => {
  const {
    type,
    required,
    name,
    control,
    label,
    setValue,
    watch,
    sourceFieldName,
    viewValueByDefault = false,
    disabled
  } = props;

  const sourceFieldValue = watch(sourceFieldName);

  const [isEditing, setIsEditing] = useState<boolean>(viewValueByDefault);

  React.useEffect(() => {
    function camelize(str = "") {
      return (
        str
          ?.toLowerCase()
          // Replaces any - or _ characters with a space
          .replace(/[-_]+/g, " ")
          // Removes any non alphanumeric characters
          .replace(/[^\w\s]/g, "")
          // Uppercase the first character in each group immediately following a space
          // (delimited by spaces)
          .replace(/ (.)/g, function ($1) {
            return $1.toUpperCase();
          })
          // Removes spaces
          .replace(/ /g, "")
      );
    }
    setValue(name, camelize(sourceFieldValue));
  }, [sourceFieldValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => {
        const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value;

          if (type === "number") {
            const splitValue = value.split(".");
            // allow only number and dot
            value = value.replace(/[^0-9\.]/g, ""); //eslint-disable-line
            // allow only one dot
            if (splitValue.length > 2) {
              value = value.replace(/\.+$/, "");
            }
            // convert back to string
            if (splitValue.length === 2) {
              // after dot allow max 3 digit
              splitValue[1] = splitValue[1].substring(0, 3);
              value = splitValue.join(".");
            }
          }
          e.target.value = value;
          onChange(e);
        };

        return (
          <Box
            sx={
              viewValueByDefault
                ? {
                    backgroundColor: "grey.100",
                    p: 1,
                    mb: 2
                  }
                : {}
            }
          >
            {isEditing ? (
              <>
                <RenderLabelValue
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography sx={{ fontWeight: 600, fontSize: 12, pb: 0, color: "#333333" }}>
                        Key
                      </Typography>
                      {!disabled && (
                        <Button
                          sx={(theme) => ({
                            py: 0,
                            fontSize: theme.typography.fontSize * 0.8
                          })}
                          size="small"
                          onClick={() => setIsEditing(!isEditing)}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  }
                  value={value || "Provide a label to generate the field key"}
                />
              </>
            ) : (
              <MUITextField
                variant="standard"
                name={name}
                value={value ?? ""}
                onChange={onInputChange}
                onBlur={onBlur}
                required={required}
                type="text"
                error={invalid}
                label={label}
                fullWidth
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
                helperText={error ? error.message : null}
              />
            )}
          </Box>
        );
      }}
    />
  );
};

export default AlphaNumericKeyField;
