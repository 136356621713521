import React from "react";
import { Box } from "@mui/material";
import { Control } from "react-hook-form";
import * as yup from "yup";

import { TextField, ToggleButtonGroup } from "@packages/theme-mui-v5";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
};

export const booleanFieldSchema = yup.object().shape({
  name: yup.string()
});

const BooleanFieldFields: React.FC<Props> = (props) => {
  const { control } = props;

  return (
    <>
      <TextField name="labels.true" label="True Label" control={control} />
      <TextField name="labels.false" label="False Label" control={control} />

      <Box pb={2}>
        <ToggleButtonGroup
          options={[
            { value: "checkbox", label: "Checkbox" },
            { value: "button-group", label: "Button Group" }
          ]}
          name="display"
          label="Display"
          control={control}
          minWidth={140}
        />
      </Box>
    </>
  );
};

export default BooleanFieldFields;
