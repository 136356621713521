import { Typography } from "@mui/material";
import React from "react";
import { ListOfValuesSchema } from "@packages/service-api";

type Props = {
  schema: ListOfValuesSchema;
};

const FormModeRenderer: React.FC<Props> = () => {
  return <Typography>Form Render Mode is not currently supported</Typography>;
};

export default FormModeRenderer;
