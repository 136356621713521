import { Box } from "@mui/material";
import {
  AgGridReactProps,
  ColDef,
  DataGrid,
  ICellRendererParams,
  RowActionsCell
} from "@packages/data-grid";
import { ListOfValuesItem, ListOfValuesSchema } from "@packages/service-api";
import React, { createRef, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useInternalNavigate } from "../../../components/InternalNavigationProvider";

type Props = {
  items: ListOfValuesItem[];
  schema: ListOfValuesSchema;
};

export const itemsDataGridRef = createRef<AgGridReactProps>();

const StructuredDataItemsDataGrid: React.FC<Props> = (props) => {
  const { items, schema } = props;

  const navigate = useInternalNavigate();

  const defaultColDef: ColDef = {
    filter: true,
    sortable: false,
    resizable: true,
    enableCellChangeFlash: true
  };

  const rowData = useMemo(() => {
    const columnKeys = schema.layout.grid.map((layoutItem) => layoutItem.key);

    return items.map((item) => {
      return columnKeys.reduce((itemData, key) => ({ ...itemData, [key]: item.attributes[key] }), {
        itemId: item.itemId,
        schemaId: item.schemaId
      });
    });
  }, [items, schema]);

  React.useEffect(() => {
    if (Array.isArray(rowData)) {
      itemsDataGridRef?.current?.api?.updateGridOptions({ rowData });
    }
  }, [rowData]);

  const ActionCellRenderer = (params: ICellRendererParams) => {
    const { itemId, schemaId } = params.data;

    return (
      <RowActionsCell
        actions={[
          {
            icon: "edit",
            color: "primary",
            tooltip: "Edit Item",
            action: () => navigate(`/schemas/${schemaId}/items/${itemId}/edit`)
          },
          {
            icon: "delete",
            color: "error",
            tooltip: "Delete",
            action: () => navigate(`/schemas/${schemaId}/items/${itemId}/delete`)
          }
        ]}
      />
    );
  };

  const columnDefs: ColDef[] = useMemo(() => {
    const columns = schema.layout.grid.reduce((columns, layoutItem) => {
      const field = schema.fields.find((f) => f.fieldKey === layoutItem.key);

      columns.push({
        field: layoutItem.key,
        headerName: field?.label[0]?.value || layoutItem.key,
        cellDataType: "text"
      });

      return columns;
    }, []);

    columns.push({
      cellRenderer: ActionCellRenderer,
      filter: false,
      editable: false,
      sortable: false,
      minWidth: 80,
      maxWidth: 80
    });

    return columns;
  }, [schema]);

  return (
    <Box>
      <DataGrid
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        enableCsvExport={true}
        enableQuickSearch={true}
        gridRef={itemsDataGridRef}
      />
      <Outlet />
    </Box>
  );
};

export default StructuredDataItemsDataGrid;
