import * as yup from "yup";
import { ListOfValuesField, ListOfValuesItem } from "@packages/service-api";
import { DynamicFormValues } from "./DynamicForm";

/**
 * Builds props for `useForm` from the given schema `fields` and current `item` values.
 *
 * @example
 * const props = useMemo(() => getDynamicFormStuff(schema.fields, item), [schema, item]);
 * const form = useForm(props);
 */
export function getDynamicFormProps(fields: ListOfValuesField[], item?: ListOfValuesItem) {
  return fields.reduce(
    (memo, field) => {
      let fieldValue = item?.attributes[field.fieldKey];

      // if not found, fallback default values by datatype
      if (fieldValue === undefined) {
        if (field.type === "string[]") {
          fieldValue = [];
        } else if (field.type === "markdown") {
          fieldValue = "";
        } else if (field.type === "markdown[]") {
          fieldValue = [];
        } else if (field.type === "number") {
          fieldValue = ""; // blank text field
        } else {
          fieldValue = "";
        }
      }

      memo.defaultValues[field.fieldKey] = fieldValue;

      return memo;
    },
    { defaultValues: {} as DynamicFormValues }
  );
}

/**
 * Returns a `yup` schema for a primitive data type and some conditions.
 */
export function getDataTypeYupSchema(type: "string" | "number", opts: { required?: boolean }) {
  let yupSchema: yup.Schema =
    type === "string" ? yup.string() : type === "number" ? yup.number() : null;

  if (type === null) {
    throw new Error(`Unsupported field type: ${type}`);
  }

  if (opts.required) {
    yupSchema = yupSchema.required();
  }

  return yupSchema;
}
