import { useQuery } from "react-query";
import { ListOfValuesApi } from "../../..";
import { ListOfValuesSchemaDocumentResponse } from "../../../types";
import { schemasSingleQueryKey } from "../query-cache";

export const useGetSchemaByIdOrKey = (idOrKey: string) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery<
    ListOfValuesSchemaDocumentResponse,
    Error
  >(schemasSingleQueryKey(idOrKey), () => ListOfValuesApi.Schemas.getByIdOrKey(idOrKey), {
    enabled: !!idOrKey
  });

  return {
    schema: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchSites: refetch
  };
};
