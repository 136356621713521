import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { ApplicationIcon, PageToolbar } from "@packages/theme-mui-v5";
import { useNavigate } from "react-router-dom";
import {
  ListOfValuesRenderType,
  ListOfValuesSchema,
  useGetSchemaPermissions
} from "@packages/service-api";
import SchemaViewModeToggleGroup from "./SchemaViewTypeToggleGroup";

type Props = {
  onViewModeChange: (nextViewMode: ListOfValuesRenderType) => void;
  schema: ListOfValuesSchema;
  supportedViewModes: ListOfValuesRenderType[];
  viewMode: ListOfValuesRenderType;
  returnPath?: string;
};

const SchemaToolbar: React.FC<Props> = (props) => {
  const { onViewModeChange, returnPath, schema, supportedViewModes, viewMode } = props;
  const permissions = useGetSchemaPermissions(schema);
  const navigate = useNavigate();

  const toolbarActions = React.useMemo(() => {
    const actions = [];
    actions.push({
      type: "component",
      element: supportedViewModes.length > 1 && (
        <SchemaViewModeToggleGroup
          onChange={(viewMode) => onViewModeChange(viewMode)}
          schema={schema}
          selected={viewMode}
          supportedViewModes={supportedViewModes}
        />
      )
    });

    if (permissions.configure) {
      actions.push({
        element: (
          <Tooltip title="Configure Schema">
            <IconButton
              color="primary"
              onClick={() =>
                window.open(
                  `${location.origin}/list-of-values/schemas/${schema?.schemaId}`,
                  "_blank"
                )
              }
            >
              <ApplicationIcon name="settings" />
            </IconButton>
          </Tooltip>
        ),
        type: "component"
      });
    }

    return actions;
  }, [permissions, viewMode]);

  return (
    <PageToolbar
      goBack={returnPath ? () => navigate(returnPath) : undefined}
      title={schema?.schemaName}
      rightActions={toolbarActions}
    />
  );
};

export default SchemaToolbar;
