import React, { useCallback, useContext } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

type NavigationState = {
  basePath: string;
  getPath: (path: string, omitLeadingSlash?: boolean) => string;
  navigate: (to: string, options?: NavigateOptions) => void;
};

export const NavigationContext = React.createContext<NavigationState>({
  basePath: undefined,
  getPath: (str: string) => str,
  navigate: () => true
});

const InternalNavigationProvider: React.FC<{ children: React.ReactNode; basePath?: string }> = ({
  children,
  basePath
}) => {
  const routerNavigate = useNavigate();

  const getPath = useCallback(
    (path: string, omitLeadingSlash?: boolean) => {
      let base = basePath ?? "";

      if (omitLeadingSlash && base && base.startsWith("/")) {
        base = base.substring(1);
      }
      if (base.endsWith("/")) {
        base = base.substring(0, base.length - 1);
      }

      return `${base ? `${base}/` : ""}${path}`;
    },
    [basePath]
  );

  const navigate = useCallback(
    (to: string, options?: NavigateOptions) => {
      routerNavigate(getPath(to), options);
    },
    [basePath, getPath, routerNavigate]
  );

  return (
    <NavigationContext.Provider value={{ basePath, getPath, navigate }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default InternalNavigationProvider;

export const useInternalNavigate = () => {
  const { navigate } = useContext(NavigationContext);
  return navigate;
};

export const useInternalNavigation = () => {
  return useContext(NavigationContext);
};
