import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import MessageBus from "@packages/message-bus";
import { errorBoundary } from "@packages/theme-mui-v5";

import applicationConfig from "./config";
import ManualEntry from "./ManualEntry";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: ManualEntry,
  errorBoundary
});

export async function mount(props) {
  MessageBus.publish("app.navbar.title", { title: applicationConfig.displayName });
  MessageBus.publish("app.load", applicationConfig);
  return lifecycles.mount(props);
}

export async function unmount(props) {
  MessageBus.publish("app.unload", {});
  return lifecycles.unmount(props);
}

export const { bootstrap } = lifecycles;
