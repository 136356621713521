import * as React from "react";
import { SelectElementProps, Option } from "./Select";
import { Controller, FieldValues } from "react-hook-form";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Location, useGetLocations } from "@packages/service-api";
import { WithLoader } from "../WithLoader";
import LocationTree from "../AppSidebar/LocationTree";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography
} from "@mui/material";
import { useClickOutside } from "../../hooks";

type SiteLocationSelectProps<T> = SelectElementProps<T> & {
  applicationId: string;
  siteId: string;
  selected?: Location | string;
  // Run function to format list like add/remove or disable list (wrap in useCallback)
  updateOptions?: (item: Option[]) => Option[];
  onOptionChange?: (data: Location) => void;
  allowAnyLocationSelection?: boolean;
};

function SiteLocationSelect<TFieldValues extends FieldValues>(
  props: SiteLocationSelectProps<TFieldValues>
): JSX.Element {
  const {
    selected,
    name,
    label,
    control,
    helperText,
    required,
    variant,
    disabled,
    applicationId,
    siteId,
    allowAnyLocationSelection,
    onOptionChange
  } = props;

  const { locations, loading } = useGetLocations(applicationId, siteId);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = React.useState<Location>();
  const ref = React.useRef();

  // when we pass only locationId as name we will get Location dynamically. Most of the places we need only locationId so added this features.
  const isString = React.useMemo(() => name === "locationId", [name]);

  useClickOutside(ref, () => {
    setOpen(false);
  });

  const { selectedLocationTitle } = React.useMemo(() => {
    if (!selectedLocation) {
      return {};
    }

    const selectedLocationTitle = [];
    const selectedAllLocationIds = [];

    const getParent = (locationId) => {
      const location = locations.find((data) => data?.locationId === locationId);
      if (location) {
        selectedLocationTitle.unshift(location.name);
        selectedAllLocationIds.unshift(location.locationId);
        getParent(location.parentLocationId);
      }
    };

    getParent(selectedLocation.locationId);

    return {
      selectedLocationTitle: selectedLocationTitle.join(" > "),
      selectedAllLocationIds
    };
  }, [locations, selectedLocation]);

  React.useEffect(() => {
    if (isString) {
      const location = locations.find((data) => data.locationId === selected);
      if (location) {
        setSelectedLocation(location);
      }
    } else {
      setSelectedLocation(selected as Location);
    }
  }, [selected, locations, isString]);

  return (
    <WithLoader loading={loading}>
      <FormControl
        disabled={disabled}
        className="mui-select"
        fullWidth
        ref={ref}
        variant={variant || "standard"}
        sx={{ mt: 2 }}
      >
        <Controller
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            const handleChange = (data: Location) => {
              if (onOptionChange) {
                onOptionChange(data);
              }
              const item = isString ? data.locationId : data;
              onChange(item);
              setSelectedLocation(data);
              setOpen(false);
            };

            const locationData = React.useMemo(() => {
              return locations?.find((data) => data.locationId === selected);
            }, [locations, value]);

            return (
              <Box
                sx={(theme) => ({
                  pb: theme.spacing(0.5),
                  position: "relative"
                })}
              >
                <Grid
                  py={2}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="nowrap"
                >
                  <InputLabel
                    required={required}
                    shrink
                    error={!!error?.message}
                    sx={(theme) => ({
                      mb: 3,
                      fontWeight: 600,
                      color: error?.message ? theme.palette.error.main : theme.palette.text.primary
                    })}
                  >
                    {label}
                  </InputLabel>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    borderRadius={0}
                    py={0.5}
                    sx={(theme) => ({
                      borderBottom: `1px solid ${theme.palette.grey["A900"]}`,
                      cursor: "pointer"
                    })}
                    onClick={() => setOpen(true)}
                  >
                    <Typography variant="h6">
                      {selectedLocationTitle ||
                        (!selectedLocation && <>Please select a location to continue</>)}
                    </Typography>
                    <ArrowDropDownIcon
                      sx={(theme) => ({
                        pl: 1,
                        color: `1px solid ${theme.palette.action.active}`
                      })}
                    />
                  </Grid>
                </Grid>
                {open && (
                  <Box
                    sx={(theme) => ({
                      position: "absolute",
                      top: 58,
                      width: "100%",
                      zIndex: 3,
                      background: theme.palette.secondary.contrastText
                    })}
                  >
                    <Paper sx={{ borderRadius: 0 }}>
                      <LocationTree
                        selected={locationData}
                        loading={loading}
                        locations={locations}
                        onChange={handleChange}
                        allowAnyLocationSelection={allowAnyLocationSelection}
                      />
                    </Paper>
                  </Box>
                )}
                <FormHelperText error={true}>{error?.message}</FormHelperText>
              </Box>
            );
          }}
          name={name}
          control={control}
        />

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </WithLoader>
  );
}

export default SiteLocationSelect;
