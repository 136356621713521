import { ThemeProvider, AppContainer, SnackbarProvider } from "@packages/theme-mui-v5";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Router from "./Router";
import applicationConfig from "./config";
import { ApiProvider } from "@packages/service-api";
import { initializeSentry } from "@packages/sentry";

initializeSentry(applicationConfig.name);

export default function ManualEntry() {
  return (
    <ApiProvider>
      <AppContainer appConfig={applicationConfig}>
        <ThemeProvider provider={MUIThemeProvider}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider>
              <Router />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AppContainer>
    </ApiProvider>
  );
}
