import * as React from "react";
import { FormGroup } from "@mui/material";
import { Resolver, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LayoutItem, useGetSchemaByIdOrKey } from "@packages/service-api";

import FormErrors from "./FormErrors";
import { Select, TextField } from "@packages/theme-mui-v5";

interface Props {
  item?: LayoutItem | Partial<LayoutItem>;
  onSubmit?: (items: unknown) => void;
  onClose?: () => void;
}

export type SchemaLayoutFormRef = {
  onSubmit: () => void;
};

const layoutGroupSchema = yup.object().shape({
  name: yup.string().required("Name is required")
});

const layoutFieldSchema = yup.object().shape({
  key: yup.string().required("Field is required")
});

const generateInitialValues = (layoutItem: Partial<LayoutItem> = {}) => {
  return {
    ...layoutItem,
    displayOrder: layoutItem.displayOrder ?? 0,
    type: layoutItem.type ?? "field"
  };
};

const SchemaLayoutForm = React.forwardRef<SchemaLayoutFormRef, Props>((props, ref) => {
  const { item, onSubmit } = props;
  const { idOrKey } = useParams<{ idOrKey: string }>();
  const { schema } = useGetSchemaByIdOrKey(idOrKey);

  const formSchema = React.useMemo(
    () => (item.type === "field" ? layoutFieldSchema : layoutGroupSchema),
    [item?.type]
  );

  const { handleSubmit, control, formState } = useForm<Partial<LayoutItem>>({
    defaultValues: generateInitialValues(item),
    // typed as any as it's impossible to make these type coercion work between Yup, react-hook-form and our code.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(formSchema as any) as unknown as Resolver<Partial<LayoutItem>>
  });

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  const fieldOptions = React.useMemo(() => {
    return schema?.fields.map((item) => ({ label: item.label[0]?.value, value: item.fieldKey }));
  }, [schema]);

  return (
    <FormGroup onSubmit={handleSubmit(onSubmit)}>
      <FormErrors errors={formState.errors} />
      {item.type === "field" && (
        <Select
          value={item.layoutId}
          options={fieldOptions}
          labelKey="value"
          valueKey="value"
          name="key"
          label="Field"
          control={control}
          required
        />
      )}
      {item.type === "group" && (
        <TextField name="name" label="Group Name" control={control} required />
      )}
    </FormGroup>
  );
});

SchemaLayoutForm.displayName = "SchemaLayoutForm";

export default SchemaLayoutForm;
