import React from "react";

import {
  useGetSchemaItems,
  ListOfValuesSchema,
  useGetSchemaPermissions
} from "@packages/service-api";
import { ApplicationIcon, WithLoader } from "@packages/theme-mui-v5";

import StructuredDataItemsDataGrid from "./StructuredDataDataGrid";
import { Box, Button } from "@mui/material";
import { useInternalNavigate } from "../../../components/InternalNavigationProvider";

type Props = {
  schema: ListOfValuesSchema;
};

const StructuredDataRenderer: React.FC<Props> = (props) => {
  const { schema } = props;

  const navigate = useInternalNavigate();

  const { items, loading } = useGetSchemaItems(schema.schemaId);
  const { configure } = useGetSchemaPermissions(schema);

  return (
    <WithLoader loading={loading}>
      {configure && (
        <Box sx={{ my: 2, px: 2, justifyContent: "flex-end", display: "flex" }}>
          <Button
            variant="contained"
            size="small"
            startIcon={<ApplicationIcon name="plus" />}
            onClick={() => navigate(`/schemas/${schema?.schemaId}/items/new`)}
          >
            Add Item
          </Button>
        </Box>
      )}
      <StructuredDataItemsDataGrid schema={schema} items={items} />
    </WithLoader>
  );
};

export default StructuredDataRenderer;
