import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ApplicationIcon } from "@packages/theme-mui-v5";
import { ListOfValuesRenderType, ListOfValuesSchema } from "@packages/service-api";

type Props = {
  onChange: (viewMode: ListOfValuesRenderType) => void;
  selected: ListOfValuesRenderType;
  schema: ListOfValuesSchema;
  supportedViewModes: ListOfValuesRenderType[];
};

const SchemaViewModeToggleGroup: React.FC<Props> = (props) => {
  const { onChange, schema, selected, supportedViewModes } = props;

  const { gridEnabled, formEnabled } = React.useMemo(() => {
    return {
      gridEnabled: supportedViewModes.includes("grid"),
      formEnabled: supportedViewModes.includes("form")
    };
  }, [schema, supportedViewModes]);

  if (!gridEnabled && !formEnabled) {
    return null;
  }

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      onChange={(_e, value) => {
        if (value) {
          onChange(value);
        }
      }}
      size="small"
      sx={{
        "&:hover": {
          backgroundColor: "unset"
        }
      }}
      value={selected}
    >
      {gridEnabled && (
        <ToggleButton value="grid">
          <ApplicationIcon name="grid" />
        </ToggleButton>
      )}
      {formEnabled && (
        <ToggleButton value="form">
          <ApplicationIcon name="list" />
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

export default SchemaViewModeToggleGroup;
