import * as yup from "yup";
import { LayoutField, ListOfValuesField } from "@packages/service-api";
import { FormApi, ReactFormApi } from "@tanstack/react-form";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { MarkdownField } from "@packages/theme-mui-v5";
import { Delete } from "@packages/theme-mui-v5/src/components/icons/feather";
import { getDataTypeYupSchema } from "./DynamicFormUtils";

export type DynamicFormFieldProps = {
  form: FormApi<any, any> & ReactFormApi<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  field: LayoutField;
  fields: Array<ListOfValuesField>;
};

export default function DynamicFormField({ form, field, fields }: DynamicFormFieldProps) {
  const schemaField = fields.find((f) => field.key === f.fieldKey);
  const name = field.key;
  const label = schemaField?.label[0]?.value;
  const required = schemaField?.isRequired;

  switch (schemaField?.type) {
    case "number":
      return (
        <form.Field
          name={name}
          validators={{ onChange: getDataTypeYupSchema("number", { required }) }}
        >
          {(field) => {
            const error = field.state.meta.errors.length > 0 && field.state.meta.isTouched;
            const helperText = field.state.meta.errors.join(", ");

            return (
              <TextField
                type="number"
                variant="standard"
                id={name}
                label={label}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
                required={required}
                error={error}
                helperText={helperText}
                InputLabelProps={{ shrink: true }}
              />
            );
          }}
        </form.Field>
      );

    case "string":
      return (
        <form.Field
          name={name}
          validators={{ onChange: getDataTypeYupSchema("string", { required }) }}
        >
          {(field) => {
            const error = field.state.meta.errors.length > 0 && field.state.meta.isTouched;
            const helperText = field.state.meta.errors.join(", ");

            return (
              <TextField
                autoFocus
                id={name}
                variant="standard"
                label={label}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required={required}
                error={error}
                helperText={helperText}
              />
            );
          }}
        </form.Field>
      );

    case "markdown":
      return (
        <form.Field
          name={name}
          validators={{ onChange: getDataTypeYupSchema("string", { required }) }}
        >
          {(field) => {
            const error = field.state.meta.errors.length > 0 && field.state.meta.isTouched;
            const helperText = field.state.meta.errors.join(", ");
            return (
              <MarkdownField
                label={label}
                value={field.state.value || ""}
                onChange={(value) => field.handleChange(value)}
                required={required}
                error={error}
                helperText={helperText}
                markdownHeight="200px"
              />
            );
          }}
        </form.Field>
      );

    case "markdown[]":
      return (
        <form.Field name={name} mode="array" validators={{ onChange: yup.array().required() }}>
          {(arrayField) => {
            return (
              <>
                {arrayField.state.value?.map?.((_, i) => {
                  return (
                    <form.Field
                      key={i}
                      name={`${name}[${i}]`}
                      validators={{ onChange: getDataTypeYupSchema("string", { required }) }}
                    >
                      {(itemField) => {
                        const itemLabel = `${label} ${i + 1}`;
                        const error =
                          itemField.state.meta.errors.length > 0 && itemField.state.meta.isTouched;
                        const helperText = itemField.state.meta.errors.join(", ");
                        const isLastItem = i === arrayField.state.value.length - 1;

                        return (
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: `1fr fit-content(100%)`,
                              gap: 1,
                              alignItems: "center"
                            }}
                          >
                            <MarkdownField
                              label={itemLabel}
                              value={itemField.state.value || ""}
                              onChange={(value) => itemField.handleChange(value)}
                              required={required}
                              error={error}
                              helperText={helperText}
                              autoFocus={isLastItem}
                              markdownHeight="200px"
                            />

                            <Box>
                              <IconButton
                                type="button"
                                onClick={() => arrayField.removeValue(i)}
                                sx={{ flexShrink: 1 }}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      }}
                    </form.Field>
                  );
                })}

                <Box>
                  <Button
                    type="button"
                    size="small"
                    variant="outlined"
                    onClick={() => arrayField.pushValue("")}
                  >
                    Add {label}
                  </Button>
                </Box>
              </>
            );
          }}
        </form.Field>
      );

    case "string[]":
      return (
        <form.Field name={name} mode="array" validators={{ onChange: yup.array().required() }}>
          {(arrayField) => {
            return (
              <>
                {arrayField.state.value?.map?.((_, i) => {
                  return (
                    <form.Field
                      key={i}
                      name={`${name}[${i}]`}
                      validators={{ onChange: getDataTypeYupSchema("string", { required }) }}
                    >
                      {(itemField) => {
                        const itemLabel = `${label} ${i + 1}`;
                        const error =
                          itemField.state.meta.errors.length > 0 && itemField.state.meta.isTouched;
                        const helperText = itemField.state.meta.errors.join(", ");
                        const isLastItem = i === arrayField.state.value.length - 1;

                        return (
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: `1fr fit-content(100%)`,
                              gap: 1,
                              alignItems: "center"
                            }}
                          >
                            <TextField
                              id={name}
                              variant="standard"
                              label={itemLabel}
                              value={itemField.state.value}
                              onChange={(e) => itemField.handleChange(e.target.value)}
                              InputLabelProps={{ shrink: true }}
                              required={required}
                              error={error}
                              helperText={helperText}
                              autoFocus={isLastItem}
                            />

                            <Box>
                              <IconButton
                                type="button"
                                onClick={() => arrayField.removeValue(i)}
                                sx={{ flexShrink: 1 }}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      }}
                    </form.Field>
                  );
                })}

                <Box>
                  <Button
                    type="button"
                    size="small"
                    variant="outlined"
                    onClick={() => arrayField.pushValue("")}
                  >
                    Add {label}
                  </Button>
                </Box>
              </>
            );
          }}
        </form.Field>
      );

    default:
      return null;
  }
}
