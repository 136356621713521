import * as React from "react";
import Select, { SelectElementProps, Option } from "./Select";
import { FieldValues } from "react-hook-form";
import { WithLoader } from "../WithLoader";
import { useGetPermissionsByApplicationId } from "@packages/service-api";

type ApplicationPermissionSelectProps<T> = SelectElementProps<T> & {
  applicationId: string;
  // Run function to format list like add/remove or disable list (wrap in useCallback)
  updateOptions?: (item: Option[]) => Option[];
};

function ApplicationPermissionSelect<TFieldValues extends FieldValues>(
  props: ApplicationPermissionSelectProps<TFieldValues>
): JSX.Element {
  const { applicationId, updateOptions } = props;
  const { permissions, loading } = useGetPermissionsByApplicationId(applicationId);

  const permissionsOptions = React.useMemo(() => {
    if (permissions?.length) {
      const lists = permissions.map((item) => ({
        id: item.permissionTypeId,
        value: item.permissionTypeId,
        label: item.name
      }));

      return updateOptions ? updateOptions(lists) : lists;
    }
    return [];
  }, [permissions, updateOptions]);

  return (
    <WithLoader loading={loading}>
      <Select
        options={permissionsOptions}
        sortOptions
        name={props.name}
        label={props.label || "Permission"}
        labelKey="label"
        valueKey="value"
        {...props}
      />
    </WithLoader>
  );
}

export default ApplicationPermissionSelect;
