import { UseQueryOptions, useQuery } from "react-query";
import { Application, ApplicationsListResponse, HTTPError, QueryOptions } from "../../../types";
import { CommonDataApi } from "../../..";
import { applicationsList } from "./query-cache";

/**
 * Hook to get a list of applications
 */
export const useGetApplications = (
  query: Partial<Application> = {},
  queryOptions: QueryOptions = {}
) => {
  const options: UseQueryOptions<ApplicationsListResponse, HTTPError> = {
    queryKey: applicationsList(),
    queryFn: () => CommonDataApi.Applications.get(query, queryOptions)
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(options);

  return {
    applications: data ? data.data : [],
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchSites: refetch
  };
};
