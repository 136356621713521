import { UseQueryOptions, useQuery } from "react-query";
import {
  useSelectedLocation,
  OnlineLogSheetsApi,
  HTTPError,
  ListOfValuesSchemaListResponse
} from "../../..";

export const useSheetsByLocationId = (applicationId: string) => {
  const { loading: selectedLocationLoading, selectedLocation } = useSelectedLocation(applicationId);

  const queryOptions: UseQueryOptions<ListOfValuesSchemaListResponse, HTTPError> = {
    enabled: !!selectedLocation?.locationId,
    queryKey: ["sheets", "sheets-by-location-id", selectedLocation?.locationId],
    queryFn: () =>
      OnlineLogSheetsApi.ManualEntry.Sheets.getByLocationId(
        selectedLocation?.applicationId,
        selectedLocation?.locationId
      )
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(queryOptions);

  return {
    sheets: data?.data,
    error: error,
    loading: selectedLocationLoading || isLoading,
    isFetching: isFetching,
    refetchNodes: refetch
  };
};
