import * as React from "react";
import Select, { Option, SelectElementProps } from "./Select";
import { FieldValues } from "react-hook-form";
import { useGetApplications } from "@packages/service-api";
import { WithLoader } from "../WithLoader";

type Props<T = Option> = SelectElementProps<T> & {
  onOptionChange?: (option: T | undefined) => void;
};

function ApplicationSelect<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>
): JSX.Element {
  const { applications, loading } = useGetApplications();

  const applicationsOptions = React.useMemo(() => {
    if (applications?.length) {
      return applications.map((item) => ({
        id: item.applicationName,
        value: item.applicationId,
        label: item.applicationName
      }));
    }
    return [];
  }, [applications]);

  return (
    <WithLoader loading={loading}>
      <Select
        options={applicationsOptions}
        sortOptions
        name={props.name}
        label={props.label || "Application"}
        labelKey="label"
        valueKey="value"
        {...props}
      />
    </WithLoader>
  );
}

export default ApplicationSelect;
