import { useCallback, useMemo } from "react";
import {
  ListOfValuesPermissionType,
  ListOfValuesSchema,
  ListOfValuesSchemaPermissions
} from "../../../types";
import { useUserProfile } from "../../auth";
import { Auth } from "../../..";

type HasPermissionsMap = Record<ListOfValuesPermissionType, boolean>;
type UseHasPermissionsResponse = HasPermissionsMap;
type Key = keyof ListOfValuesSchemaPermissions;

export const useGetSchemaPermissions = (schema: ListOfValuesSchema): UseHasPermissionsResponse => {
  const userProfile = useUserProfile();

  const checkPermission = useCallback(
    (key: Key) => {
      if (!userProfile || !schema) return;
      if (userProfile.isSysAdmin) return true;
      return (
        schema.permissions[key]?.length === 0 ||
        schema.permissions[key].some(({ permissionTypeId, locationId, applicationId }) => {
          return Auth.checkPermission({
            applicationId,
            permissions: [permissionTypeId],
            locationId
          });
        })
      );
    },
    [schema, userProfile]
  );

  return useMemo(() => {
    const permissionMap: HasPermissionsMap = {
      configure: checkPermission("configure"),
      read: checkPermission("read"),
      write: checkPermission("write")
    };
    return permissionMap;
  }, [schema, userProfile]);
};
